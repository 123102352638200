import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";
import config from "../../../public/config";
import { ApolloProvider } from "vue-apollo";
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const authLink = setContext(async (_, { headers }) => {
  const token = Cookies.get("app-token");
  
  return {
    headers: {
      ...headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    }
  };
});

const httpLink = createHttpLink({
  uri: config.apiGraphqlBaseUrl,
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const apolloProvider = new ApolloProvider({
  defaultClient: apolloClient,
});

export default apolloProvider;
