<template>
  <div>
    <!-- top navigation bar -->
    <v-app-bar app elevation="2" height="60" color="#fff" style="z-index: 100">
      <v-app-bar-nav-icon @click="toggleNav"> </v-app-bar-nav-icon>
      <v-toolbar-title>AMALITECH PAYROLL</v-toolbar-title>
      <custom-v-spacer></custom-v-spacer>
      <template>
        <v-menu offset-y content-class="menu-custom-class">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-icon>mdi-dialpad</v-icon>
            </v-btn>
          </template>
          <template>
            <v-row class="bg-white darken-1 w-fit">
              <h4 class="text-center bg-white darken-1 text-[14px] pt-2">
                Apps
              </h4>
              <v-col
                v-for="(app, index) in displayedApps"
                :key="index"
                :cols="calculateColumns(displayedApps?.length)"
                class="text-center"
              >
                <v-tooltip :bottom="app.bottom">
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      @click.prevent="routeUrl(app.num)"
                      link
                      v-on="on"
                    >
                      <v-list-item-title>
                        <component :is="app.icon" />
                        <p class="text-caption">{{ app.title }}</p>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ app.text }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-menu>
      </template>
    </v-app-bar>

    <!-- navigation sidebar -->
    <v-navigation-drawer
      v-model="drawer"
      app
      :mini-variant.sync="mini"
      color="accent text-white"
      dark
      mobile-breakpoint="5"
      bottom
    >
      <v-list-item class="px-2"></v-list-item>
      <!-- navigation sidebar items -->
      <v-list>
        <v-list-item :to="{ name: 'employee' }">
          <v-icon class="mr-5" color="white"> mdi-account-supervisor </v-icon>
          <v-list-item-content>
            <v-list-item-title>Employees Salary</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-icon class="mr-5" color="white"> mdi-cog </v-icon>
            <v-list-item-content>
              <v-list-item-title>Configuration</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="definitions">
            <v-list-item-content>
              <v-list-item-title>Definitions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="statutory-deductions">
            <v-list-item-content>
              <v-list-item-title>Statutory Deduction</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-icon class="mr-5" color="white"> mdi-view-agenda </v-icon>
            <v-list-item-content>
              <v-list-item-title>Payroll</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="service-center-payroll">
            <v-list-item-content>
              <v-list-item-title>Service Center</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="training-center-payroll">
            <v-list-item-content>
              <v-list-item-title>Training Center</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="operations-payroll">
            <v-list-item-content>
              <v-list-item-title>Operations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="management-payroll" v-if="isConfidential">
            <v-list-item-content>
              <v-list-item-title>Management</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="nsp">
            <v-list-item-content>
              <v-list-item-title>NSP</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-icon class="mr-5" color="white"> mdi-database-edit </v-icon>
            <v-list-item-content>
              <v-list-item-title>Add/Deduct</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="additions">
            <v-list-item-content>
              <v-list-item-title>Additions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="deductions">
            <v-list-item-content>
              <v-list-item-title>Deductions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group no-action>
          <template v-slot:activator>
            <v-icon class="mr-5" color="white"> mdi-chart-box </v-icon>
            <v-list-item-content>
              <v-list-item-title>Report</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item to="payslip-report" v-if="canEdit">
            <v-list-item-content>
              <v-list-item-title>Payslip</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="nsp-payslip-report" v-if="canEdit">
            <v-list-item-content>
              <v-list-item-title>NSP Payslip</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="payroll-report" v-if="isConfidential">
            <v-list-item-content>
              <v-list-item-title>Payroll</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="bank-advice-report">
            <v-list-item-content>
              <v-list-item-title>Bank Advice</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group no-action sub-group :value="true">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Pension</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item to="tier-1-report">
              <v-list-item-content>
                <v-list-item-title>Tier 1</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="tier-2-report">
              <v-list-item-content>
                <v-list-item-title>Tier 2</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="notfound">
              <v-list-item-content>
                <v-list-item-title>Tier 3</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item to="gra-report">
            <v-list-item-content>
              <v-list-item-title>GRA</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="salaryJV">
            <v-list-item-content>
              <v-list-item-title>Salary JV</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list v-if="isAdmin">
          <v-list-item @click="openSettingsModal">
            <v-icon class="mr-5" color="white"> mdi-cog </v-icon>
            <v-list-item-content>
              <v-list-item-title>Access Level</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <settings-modal
          @close="closeSettingsModal"
          :dialog="showSettingsDialog"
        ></settings-modal>
        <v-list-item @click="logout()">
          <v-icon class="mr-5" color="white"> mdi-arrow-left </v-icon>
          <v-list-item-content>
            <v-list-item-title>ARMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import * as roles from "../../services/roles";
import config from "../../../public/config";
import SettingsModal from "./SettingsModal.vue";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { apps } from "../../services/serviceData";

export default {
  components: {
    SettingsModal,
    CustomVSpacer: {
      render(createElement) {
        return createElement("div", {
          style: {
            flex: "1 1 auto",
            maxWidth: "70%",
          },
        });
      },
    },
  },
  data() {
    return {
      drawer: true,
      mini: false,
      showSettingsDialog: false,
      apps: [...apps],
    };
  },
  computed: {
    armsUrl() {
      return config.loginUrl;
    },
    isConfidential() {
      return roles.isConfidential();
    },
    canEdit() {
      return roles.canEdit();
    },
    isAdmin() {
      return roles.canAccessUsers();
    },
    permittedApps() {
      const currentUser = Cookie.get("app-token");
      const decodedUser = jwt_decode(currentUser);
      return decodedUser?.apps;
    },
    displayedApps() {
      const sortedApps = this.apps
        .filter((app) => this.permittedApps?.includes(app.key))
        .sort((a, b) => a.title.localeCompare(b.title));
      return sortedApps;
    },
    filteredApps() {
      return this.apps.filter((app) => {
        return this.accessApp(app.num);
      });
    },
  },

  methods: {
    routeUrl(el) {
      return this.apps.map((ap) => {
        const token = Cookie.get("app-token");
        if (token) {
          if (el === ap.num) {
            window.location.replace(`${ap.link}`);
          }
        }
      });
    },
    accessApp(el) {
      return this.apps.some((app) => {
        return el === app.num && this.permittedApps.includes(app.key);
      });
    },
    logout() {
      window.location.replace(this.armsUrl);
      this.$store.dispatch("auth/logout");
    },
    toggleNav() {
      this.drawer = !this.drawer;
    },
    openSettingsModal() {
      if (this.$route.path !== "/access-level") {
        this.$router.push("/access-level");
      }
    },
    closeSettingsModal() {
      this.showSettingsDialog = false;
    },
    calculateColumns(numApps) {
      if (numApps === 0) return 12;
      if (numApps <= 2) return 6;
      return 4;
    },
  },
};
</script>

<style scoped>
.menu-custom-class {
  max-width: 450px;
  max-height: 300px;
}
.v-toolbar .v-input {
  padding-top: 0;
  margin-top: 18px;
}

.v-text-field > .v-input__control > .v-input__slot {
  cursor: pointer !important;
}

.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.v-application .primary--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}

.horizontal-list .v-list-item {
  display: inline-block;
}
</style>
